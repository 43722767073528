import axios, { API_PREFIX } from 'shared/configs/axios/axios';
import { TSubscriptionPlanType } from 'shared/types/subscriptionPlanTypes';

export type TAddSubscriptionDetailsPayload = {
	numberOfPractitioners?: number;
	subscriptionPlanType: TSubscriptionPlanType;
	domainPrefix: string;
	customer: {
		firstName: string;
		lastName: string;
		email: string;
		phoneNumber?: string;
	};
};

export type TAddSubscriptionDetailsResponse = {
	id: number;
	paymentToken: string;
	amount?: number;
};

export type TCoupon = {
	couponId: string;
	newAmount: number;
	discountAmount: number;
};

export const addSubscriptionsDetails = async (
	payload: TAddSubscriptionDetailsPayload,
): Promise<TAddSubscriptionDetailsResponse> => {
	const { data } = await axios.post<TAddSubscriptionDetailsResponse>(
		`${API_PREFIX}/subscriptions`,
		payload,
	);
	return data;
};

export const finalizeSubscription = async (payload): Promise<any> => {
	const { subscriptionId, ...rest } = payload;
	const { data } = await axios.put<TAddSubscriptionDetailsResponse>(
		`${API_PREFIX}/subscriptions/${subscriptionId}/finalize`,
		rest,
	);
	return data;
};
export const failSubscription = async (payload): Promise<any> => {
	const { subscriptionId, ...rest } = payload;
	const { data } = await axios.put<TAddSubscriptionDetailsResponse>(
		`${API_PREFIX}/subscriptions/${subscriptionId}/fail`,
		rest,
	);
	return data;
};

export const getCouponById = async (couponId, subscriptionId): Promise<TCoupon> => {
	const { data } = await axios.get<TCoupon>(`${API_PREFIX}/coupons/${couponId}`, {
		params: subscriptionId,
	});
	return data;
};
