import { ReactElement, useState } from 'react';

export type MultiStepFormReturnData = {
	currentStepIndex: number;
	isFirstStep: boolean;
	isLastStep: boolean;
	back: () => void;
	next: () => void;
	goTo: (index: number) => void;
	steps: ReactElement[];
	step: ReactElement;
};

const useMultiStepForm = (steps: ReactElement[]): MultiStepFormReturnData => {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);

	const next = (): void => {
		setCurrentStepIndex((i) => {
			if (i >= steps.length - 1) return i;
			return i + 1;
		});
	};

	const back = (): void => {
		setCurrentStepIndex((i) => {
			if (i <= 0) return i;
			return i - 1;
		});
	};

	const goTo = (index: number): void => {
		setCurrentStepIndex(index);
	};

	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		goTo,
		next,
		back,
		steps,
		isFirstStep: currentStepIndex === 0,
		isLastStep: currentStepIndex === steps.length - 1,
	};
};

export default useMultiStepForm;
