import { FC } from 'react';

import { ReactComponent as MailIcon } from 'assets/svg/mailIcon.svg';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import { capitalize } from 'shared/utils/capitalize';

import styles from './Completion.module.scss';

const Completion: FC = (): JSX.Element => {
	const planType = useGetPlanType();

	return (
		<section className={styles.section}>
			<div className={styles.container}>
				<h1 className={styles.title}>{`Thank you for choosing our ${`${capitalize(
					planType,
				)}`} Plan!`}</h1>
				<p className={styles.text}>
					Your account will be set up within 1 business day and you will receive an email to
					activate your account
				</p>
				<MailIcon />
				<a href="https://www.corvuslink.com" className={styles.linkButton}>
					Back to Corvus Link
				</a>
			</div>
		</section>
	);
};

export default Completion;
