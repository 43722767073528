import { FC, ReactElement } from 'react';

import { ReactComponent as RequiredIcon } from 'assets/svg/required-star.svg';

import styles from './RequiredFormLabel.module.scss';

type TRequiredFormLabelProps = {
	label?: string;
	children?: ReactElement;
};

const RequiredFormLabel: FC<TRequiredFormLabelProps> = ({ label, children }): JSX.Element => {
	return (
		<p className={styles.container}>
			{label && label}
			{children && children}
			<RequiredIcon className={styles.icon} />
		</p>
	);
};

export default RequiredFormLabel;
