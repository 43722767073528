export const antdThemeStyles = {
	colorPrimary: '#00AC8E',
	colorPrimaryHover: '#33BDA5',
	colorPrimaryActive: '#006162',
	colorInfo: '#3498DB',
	colorInfoHover: '#3498DB',
	colorError: '#D03900',
	colorErrorHover: 'rgba(208, 57, 0, 0.6)',
	colorErrorActive: '9A2A00',
	colorSuccess: '#1A8A76',
	fontFamily: `'Jost', sans-serif`,
	colorText: '#1A2026',
	colorTextSecondary: '#1A2026',
	colorTextDisabled: '#BFBFBF',
	controlHeight: 40,
};
