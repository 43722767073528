import { FC } from 'react';

import AppHeader from 'pages/AppHeader';
import { Outlet } from 'react-router-dom';
import useDocumentTitle from 'shared/customHooks/useDocumentTitle';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import { capitalize } from 'shared/utils/capitalize';

const Layout: FC = (): JSX.Element => {
	const planType = useGetPlanType();

	useDocumentTitle(`Corvus Link - ${capitalize(planType)}`);

	return (
		<>
			<AppHeader />
			<Outlet />
		</>
	);
};

export default Layout;
