import { useSearchParams } from 'react-router-dom';
import { SUBSCRIPTIONS } from 'shared/types/subscriptionPlanTypes';

export const useGetPlanType = (): keyof typeof SUBSCRIPTIONS => {
	const [params] = useSearchParams();
	const { BASIC, PRACTITIONER, TEAM, ENTERPRISE } = SUBSCRIPTIONS;

	const planType = params.get('planType');

	if (planType?.includes(BASIC)) return BASIC;
	if (planType?.includes(PRACTITIONER)) return PRACTITIONER;
	if (planType?.includes(TEAM)) return TEAM;
	if (planType?.includes(ENTERPRISE)) return ENTERPRISE;

	if (
		planType !== BASIC &&
		planType !== PRACTITIONER &&
		planType !== TEAM &&
		planType !== ENTERPRISE
	) {
		return BASIC;
	}

	return planType;
};

export default useGetPlanType;
