import { FC } from 'react';

import { Form, Input } from 'antd';
import RequiredFormLabel from 'shared/ui/RequiredFormLabel';

const RegistrationPlanFormStepOne: FC = (): JSX.Element => {
	const validatePhoneNumber = (_, value): Promise<void> => {
		const isValid = /^[0-9]{10}$/.test(value) || !value;

		if (!isValid) {
			return Promise.reject('Please enter a 10-digit number.');
		}
		return Promise.resolve();
	};

	return (
		<>
			<Form.Item
				label={<RequiredFormLabel label="First Name" />}
				name="firstName"
				rules={[{ required: true, message: 'Required field' }]}
			>
				<Input placeholder="Enter First Name" />
			</Form.Item>
			<Form.Item
				label={<RequiredFormLabel label="Last Name" />}
				name="lastName"
				rules={[{ required: true, message: 'Required field' }]}
			>
				<Input placeholder="Enter Last Name" />
			</Form.Item>
			<Form.Item
				label={<RequiredFormLabel label="Email" />}
				name="email"
				rules={[{ required: true, message: 'Required field' }]}
			>
				<Input type="email" placeholder="Enter Email" />
			</Form.Item>
			<Form.Item
				name="phoneNumber"
				label="Phone Number"
				rules={[
					{
						validator: validatePhoneNumber,
					},
				]}
			>
				<Input type="tel" addonBefore="+1" placeholder="Enter Phone Number" />
			</Form.Item>
		</>
	);
};

export default RegistrationPlanFormStepOne;
