import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { PaymentElement } from '@stripe/react-stripe-js';
import { Button, Checkbox, Input, Space, message } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as CouponIcon } from 'assets/svg/coupon.svg';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import {
	TAddSubscriptionDetailsResponse,
	TCoupon,
	getCouponById,
} from 'shared/api/subscription.service';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import { isNil } from 'shared/lib/lib';
import { SUBSCRIPTIONS } from 'shared/types/subscriptionPlanTypes';
import { capitalize } from 'shared/utils/capitalize';

import styles from './StripeFormStep.module.scss';

type StripeFormStepProps = {
	setIsPrivacyTerms: Dispatch<SetStateAction<boolean>>;
	isPrivacyTerms: boolean;
	setCouponId: Dispatch<SetStateAction<string>>;
	setCouponResponse: Dispatch<SetStateAction<TCoupon>>;
	couponResponse: TCoupon;
	subscriptionResponse: TAddSubscriptionDetailsResponse;
	couponId: string;
};

const StripeFormStep: FC<StripeFormStepProps> = ({
	setIsPrivacyTerms,
	isPrivacyTerms,
	subscriptionResponse,
	setCouponId,
	couponId,
	setCouponResponse,
	couponResponse,
}): JSX.Element => {
	const planType = useGetPlanType();

	const [couponValue, setCouponValue] = useState<string>(null);
	const [error, setError] = useState(null);

	const { refetch: getCouponByIdAsync, isFetching: isFetchingGetCouponByIdAsync } = useQuery({
		queryKey: ['couponById'],
		queryFn: () => getCouponById(couponValue, { subscriptionId: subscriptionResponse.id }),
		enabled: false,
		onSuccess: (response) => {
			setCouponId(response.couponId);
			setCouponResponse(response);
		},
		onError: (error: AxiosError) => {
			handleError(error);
		},
		retry: 0,
	});

	const handleError = (error: AxiosError): void => {
		if (error?.response?.status === 404 || error?.response?.status === 400) {
			setError((error.response?.data as any)?.message ?? 'Invalid coupon');
			return;
		}

		message.error((error.response?.data as any)?.message || error.message || 'Error!');
	};

	const handleCouponValueChange = (e): void => {
		setCouponValue(e.target.value);
	};

	const handlePrivacyChange = (): void => {
		setIsPrivacyTerms((prev) => !prev);
	};

	const handleApplyCoupon = async (): Promise<void> => {
		try {
			await getCouponByIdAsync();
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	const handleDeleteCoupon = (): void => {
		setError(null);
		setCouponId(null);
		setCouponResponse(null);
		setCouponValue(null);
	};

	const priceInDollars = useMemo(() => {
		const newAmount = couponResponse?.newAmount;

		return isNil(newAmount) ? (subscriptionResponse?.amount ?? 0) / 100 : newAmount / 100;
	}, [couponResponse]);

	return (
		<div className={styles.container}>
			<PaymentElement id="payment-element" />
			<div className={styles.content}>
				{planType !== SUBSCRIPTIONS.BASIC && (
					<div>
						<p className={styles.title} style={{ marginBottom: 8 }}>
							Coupon
						</p>
						{couponId ? (
							<div className={styles.appliedCouponWrapper}>
								<div className={styles.appliedCouponInfoWrapper}>
									<CouponIcon />
									<div>
										<p>You saved ${couponResponse.discountAmount / 100}</p>
										<p className={styles.lightText}>
											Coupon {`"${couponResponse.couponId}"`} Applied
										</p>
									</div>
								</div>
								<CloseIcon onClick={handleDeleteCoupon} className={styles.closeIcon} />
							</div>
						) : (
							<>
								<Space.Compact style={{ width: '100%' }}>
									<Input
										status={error ? 'error' : null}
										maxLength={32}
										onChange={handleCouponValueChange}
									/>
									<Button
										onClick={handleApplyCoupon}
										loading={isFetchingGetCouponByIdAsync}
										htmlType="button"
										disabled={!couponValue || isFetchingGetCouponByIdAsync}
									>
										Apply
									</Button>
								</Space.Compact>
								{error ? <p className={styles.errorMessage}>{error}</p> : null}
							</>
						)}
					</div>
				)}

				<div className={styles.planInfo}>
					<h2 className={styles.infoWrapper}>
						<span className={styles.title}>{`${capitalize(planType)} Plan`}</span>
						<span className={styles.titlePrice}>${priceInDollars}</span>
					</h2>
					<p className={styles.planDescription}>${priceInDollars} / month per seat</p>
				</div>
				{couponResponse && (
					<div>
						<p className={styles.infoWrapper}>
							<span>Coupon discount</span>
							<span>${couponResponse.discountAmount / 100}</span>
						</p>
					</div>
				)}
				<div className={`${styles.infoWrapper} ${styles.total}`}>
					<span>Total</span>
					<span>${priceInDollars}</span>
				</div>
				<div className={styles.privacyWrapper}>
					<Checkbox checked={isPrivacyTerms} onChange={handlePrivacyChange} />
					<p>
						You’ll be charged the amount listed above every month until you cancel. You can cancel
						any time. By subscribing, you agree to Corvus Link’s{' '}
						<a
							href="https://www.corvuslink.com/terms-and-conditions"
							target="_blank"
							className={styles.link}
							rel="noreferrer"
						>
							Terms of Use
						</a>{' '}
						and{' '}
						<a
							href="https://www.corvuslink.com/privacy-policy"
							target="_blank"
							className={styles.link}
							rel="noreferrer"
						>
							Privacy Policy
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default StripeFormStep;
