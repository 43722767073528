import { FC } from 'react';

import Layout from 'Layout';
import CompletionPage from 'pages/CompletionPage';
import RegistrationPlanFormPage from 'pages/RegistrationPlanFormPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import styles from './App.module.scss';

const App: FC = (): JSX.Element => {
	return (
		<main className={styles.appContainer}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<RegistrationPlanFormPage />} />
						<Route path="completion" element={<CompletionPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</main>
	);
};

export default App;
