import { FC, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
	RegistrationPlanFormStepOne,
	RegistrationPlanFormStepTwo,
	StripeFormStep,
} from 'fetures/RegistrationPlanFormSteps';
import {
	TAddSubscriptionDetailsPayload,
	TAddSubscriptionDetailsResponse,
} from 'shared/api/subscription.service';
import { stripeStyles } from 'shared/configs/stripeTheme/stripeTheme';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import useMultiStepForm from 'shared/customHooks/useMultiStepForm';
import { FormWrapper } from 'shared/ui';
import { capitalize } from 'shared/utils/capitalize';

import styles from './RegistrationPlanForm.module.scss';

// declare here to load ones when form is loaded
// to avoid recreating the `Stripe` object on every render.
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RegistrationPlanForm: FC = (): JSX.Element => {
	const planType = useGetPlanType();

	const [subscriptionResponse, setSubscriptionResponse] =
		useState<TAddSubscriptionDetailsResponse>(null);
	const [allFormData, setAllFormData] = useState<TAddSubscriptionDetailsPayload>(null);
	const [isPrivacyTerms, setIsPrivacyTerms] = useState(false);
	const [couponId, setCouponId] = useState<string>(null);
	const [couponResponse, setCouponResponse] = useState(null);

	const multiStepData = useMultiStepForm([
		<RegistrationPlanFormStepOne key={0} />,
		<RegistrationPlanFormStepTwo key={1} />,
		<StripeFormStep
			key={2}
			setIsPrivacyTerms={setIsPrivacyTerms}
			isPrivacyTerms={isPrivacyTerms}
			subscriptionResponse={subscriptionResponse}
			setCouponId={setCouponId}
			couponId={couponId}
			setCouponResponse={setCouponResponse}
			couponResponse={couponResponse}
		/>,
	]);

	const isFirstTwoSteps =
		multiStepData.currentStepIndex === 0 || multiStepData.currentStepIndex === 1;

	const isStripeForm = multiStepData.currentStepIndex === 2;

	return (
		<section className={styles.container}>
			{isFirstTwoSteps && (
				<Elements
					stripe={stripePromise}
					options={{
						clientSecret: subscriptionResponse?.paymentToken,
						appearance: stripeStyles,
					}}
				>
					<FormWrapper
						title={`${capitalize(planType)} Plan`}
						multiStepData={multiStepData}
						setSubscriptionResponse={setSubscriptionResponse}
						subscriptionResponse={subscriptionResponse}
						setAllFormData={setAllFormData}
						allFormData={allFormData}
					>
						{multiStepData.step}
					</FormWrapper>
				</Elements>
			)}
			{isStripeForm && stripePromise && subscriptionResponse?.paymentToken && (
				<Elements
					stripe={stripePromise}
					options={{
						clientSecret: subscriptionResponse.paymentToken,
						appearance: stripeStyles,
					}}
				>
					<FormWrapper
						title={`${capitalize(planType)} Plan`}
						multiStepData={multiStepData}
						setSubscriptionResponse={setSubscriptionResponse}
						subscriptionResponse={subscriptionResponse}
						isPrivacyTerms={isPrivacyTerms}
						setAllFormData={setAllFormData}
						allFormData={allFormData}
						couponId={couponId}
					>
						{multiStepData.step}
					</FormWrapper>
				</Elements>
			)}
		</section>
	);
};

export default RegistrationPlanForm;
