import { FC } from 'react';

import { Form, Input, InputNumber } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import { SUBSCRIPTIONS } from 'shared/types/subscriptionPlanTypes';
import RequiredFormLabel from 'shared/ui/RequiredFormLabel';

import styles from './RegistrationPlanFormStepTwo.module.scss';

const RegistrationPlanFormStepTwo: FC = (): JSX.Element => {
	const form = useFormInstance();
	const planType = useGetPlanType();

	const validateDomain = (_, value): any => {
		if (value) {
			const regex = /^[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*)*$/;

			if (!regex.test(value)) {
				return Promise.reject('Invalid domain prefix');
			}
		}
		return Promise.resolve();
	};

	const maxPractitionersNumber = planType === SUBSCRIPTIONS.TEAM ? 200 : null;
	const isFieldRequired = planType === SUBSCRIPTIONS.ENTERPRISE || planType === SUBSCRIPTIONS.TEAM;
	const isDisabledField =
		planType === SUBSCRIPTIONS.BASIC || planType === SUBSCRIPTIONS.PRACTITIONER;

	return (
		<>
			<Form.Item
				label={
					isFieldRequired ? (
						<RequiredFormLabel label="Number of Practitioners" />
					) : (
						'Number of Practitioners'
					)
				}
				name="numberOfPractitioners"
				rules={[
					{
						required: isFieldRequired,
						message: 'Required field',
					},
				]}
			>
				<InputNumber
					disabled={isDisabledField}
					type="number"
					min={1}
					max={maxPractitionersNumber}
					placeholder="Enter Number of Practitioners"
					style={{ width: '100%' }}
				/>
			</Form.Item>
			<p className={styles.formItemLabel}>
				<RequiredFormLabel label="Domain Prefix Preference" />
			</p>
			<p className={styles.text}>
				Provide the domain prefix (<strong>________.corvuslink.com</strong>) you would like
			</p>
			<p className={styles.text}>Maximum 24 characters allowed</p>
			<Form.Item
				name="domainPrefix"
				rules={[
					{ required: true, message: 'Required field' },
					{ validator: validateDomain, message: 'Invalid domain prefix' },
				]}
			>
				<Input maxLength={24} placeholder="Enter Domain" />
			</Form.Item>
		</>
	);
};

export default RegistrationPlanFormStepTwo;
