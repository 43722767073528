import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, message } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	TAddSubscriptionDetailsPayload,
	TAddSubscriptionDetailsResponse,
	addSubscriptionsDetails,
	failSubscription,
	finalizeSubscription,
} from 'shared/api/subscription.service';
import useGetPlanType from 'shared/customHooks/useGetPlanType';
import { MultiStepFormReturnData } from 'shared/customHooks/useMultiStepForm';
import { SUBSCRIPTIONS } from 'shared/types/subscriptionPlanTypes';

import styles from './FormWrapper.module.scss';
import { getErrorMsgs } from './lib';

type FormWrapperProps = {
	title: string;
	children: ReactNode;
	multiStepData: MultiStepFormReturnData;
	setSubscriptionResponse: Dispatch<SetStateAction<TAddSubscriptionDetailsResponse>>;
	subscriptionResponse: TAddSubscriptionDetailsResponse;
	setAllFormData: Dispatch<SetStateAction<TAddSubscriptionDetailsPayload>>;
	allFormData: TAddSubscriptionDetailsPayload;
	isPrivacyTerms?: boolean;
	couponId?: string;
};

const FormWrapper: FC<FormWrapperProps> = ({
	title,
	children,
	multiStepData,
	setSubscriptionResponse,
	subscriptionResponse,
	setAllFormData,
	allFormData,
	isPrivacyTerms = true,
	couponId = null,
}): JSX.Element => {
	const [form] = useForm();
	const navigate = useNavigate();
	const planType = useGetPlanType();

	const stripe = useStripe();
	const elements = useElements();

	const [isProcessing, setIsProcessing] = useState(false);

	const { steps, currentStepIndex, isFirstStep, isLastStep, back, next } = multiStepData;
	const currentStep = currentStepIndex + 1;
	const allSteps = steps.length;

	const { mutateAsync: asyncAddSubscriptionsDetails, isLoading: isLoadingAddSubscriptionsDetails } =
		useMutation({
			mutationFn: addSubscriptionsDetails,
			onSuccess: (data) => {
				setSubscriptionResponse(data);
				next();
			},
		});

	const { mutateAsync: asyncFinalizeSubscription, isLoading: isLoadingFinalizeSubscription } =
		useMutation({
			mutationFn: finalizeSubscription,
		});

	const handleSubmitStripeForm = async (): Promise<void> => {
		if (!stripe || !elements) return;

		setIsProcessing(true);

		const setupIntent = await stripe.confirmSetup({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/completion?planType=${planType}`,
			},
			redirect: 'if_required',
		});

		if (setupIntent.error) {
			try {
				await failSubscription({
					subscriptionId: subscriptionResponse?.id,
					paymentIntentId: setupIntent.error.setup_intent.id,
				});
			} catch (e) {
				console.log(e);
			}

			message.error(setupIntent.error.message);
		} else if (setupIntent?.setupIntent.status === 'succeeded') {
			try {
				await asyncFinalizeSubscription({
					subscriptionId: subscriptionResponse?.id,
					paymentIntentId: setupIntent.setupIntent.id,
					paymentMethodId: setupIntent.setupIntent.payment_method,
					couponId,
				});
				navigate(`/completion?planType=${planType}`);
			} catch (e) {
				console.log(e);
			}

			message.success('Setup card succeeded');
		} else {
			message.error('Unexpected state');
		}

		setIsProcessing(false);
	};

	const onFinish = async (formValues): Promise<void> => {
		setAllFormData((prev) => ({
			...prev,
			...formValues,
			phoneNumber: formValues?.phoneNumber ? `+1${formValues.phoneNumber}` : undefined,
		}));
		const allValues = {
			...allFormData,
			...formValues,
			phoneNumber: formValues?.phoneNumber ? `+1${formValues.phoneNumber}` : undefined,
		};

		const payload = {
			domainPrefix: formValues.domainPrefix,
			subscriptionPlanType: planType,
			numberOfPractitioners: formValues.numberOfPractitioners,
			customer: {
				firstName: allValues.firstName,
				lastName: allValues.lastName,
				email: allValues.email,
				phoneNumber: allValues?.phoneNumber,
			},
		};

		if (currentStepIndex === 0) next();

		if (currentStepIndex === 1) {
			try {
				await asyncAddSubscriptionsDetails(payload);
			} catch (error) {
				const errorMsg = getErrorMsgs(error);

				message.error(errorMsg || error?.response?.data?.message || error?.message || 'Error!');
			}
		}

		if (currentStepIndex === 2) {
			handleSubmitStripeForm();
		}
	};

	const initialValues = {
		...allFormData,
		numberOfPractitioners: allFormData?.numberOfPractitioners ?? 1,
	};

	const buttonText = isLastStep ? 'Finish' : 'Next';
	return (
		<div className={styles.container}>
			<h1 className={styles.title}>{title}</h1>
			<p className={styles.steps}>
				Step {currentStep} of {allSteps}
			</p>
			<div className={styles.content}>
				<Form
					requiredMark="optional"
					id="registration-form"
					form={form}
					layout="vertical"
					onFinish={onFinish}
					validateTrigger="onChange"
					initialValues={initialValues}
					preserve
				>
					{children}
				</Form>
			</div>
			<div className={styles.actions}>
				{!isFirstStep && (
					<Button onClick={back} className={styles.actionButton}>
						Back
					</Button>
				)}
				<Button
					form="registration-form"
					type="primary"
					htmlType="submit"
					className={styles.actionButton}
					disabled={
						isLoadingAddSubscriptionsDetails ||
						isProcessing ||
						!isPrivacyTerms ||
						isLoadingFinalizeSubscription
					}
					loading={
						isLoadingAddSubscriptionsDetails || isProcessing || isLoadingFinalizeSubscription
					}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};

export default FormWrapper;
